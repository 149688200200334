.pagination {
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  button {
    cursor: pointer;
    padding: 10px 14px;
    border-radius: 8px;
    font-size: 26px;
    font-weight: 700;
    background-color: #df1c1b;
    border: none;
    color: #fff;
    &:active {
      opacity: 0.8;
    }
    &:disabled {
      opacity: 0.6;
    }
  }
}
