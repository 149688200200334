header {
  height: 76px;
  background: #fff;
  border: 2px solid #cccccc;
  transform: translateX(1px);
  display: flex;
  align-items: center;
  padding: 0 60px 0 15px;
  position: fixed;
  right: 0;
  z-index: 4;
}
.scrollyes {
  width: calc(100vw - 258px);
}
.scrollno {
  width: calc(100vw - 241px);
}

.search_input {
  width: 400px;
  padding: 12px 20px;
  border-radius: 7px;
  outline: none;
  font-family: "Montserrat";
  font-style: normal;
  background-image: url("../../assets/images/icons/search.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: center;
  font-size: 13px;
  line-height: 16px;
  border: none;
  &::placeholder {
    color: rgba($color: #000000, $alpha: 0.5);
  }
}
.user_wrapper_inner {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user_wrapper {
  margin-left: auto;
  color: #fff;
  &.user_active {
    .leave_acc {
      visibility: visible;
      transition: 0.3s;
      opacity: 1;
      transform: translateY(10px);
    }
  }

  .user_img_wrapper {
    width: 35px;
    height: 35px;
    background: #df1c1b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  h3 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 17px;
    color: #000000;
  }
}
.leave_acc {
  position: absolute;
  transform: translateY(-15px);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  button {
    cursor: pointer;
    background-color: red;
    padding: 12px 27px;
    border-radius: 7px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
  }
}
