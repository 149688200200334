.nav-bar_wrapper {
  display: flex;
  height: 100vh;
  font-family: "Montserrat";
  color: #000;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  min-width: 241px;
  background: #fff;
  overflow-y: auto;
  position: fixed;
  border: 2px solid #cccccc;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
.logo_wrapper {
  transform: translateY(-20px);
  margin-left: -20px;
  a {
    font-size: 26px;
  }
  img {
    width: 190px;
  }
}

.nav_list {
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 20px;
}

.nav_list_item {
  display: flex;
  align-items: center;
}

.nav_link {
  display: flex;
  align-items: center;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 15px 15px 15px 30px;
  width: 240px;
  &.active {
    background: #df1c1b;
    color: #fff;
  }
  i {
    font-size: 10px;
    padding-left: 5px;
    padding-top: 5px;
  }
}
.dropdown {
  background: #df1b1b71;
  width: 120%;
  &.drop_active {
    display: block;
  }
  &.drop_inactive {
    display: none;
  }
  a {
    display: block;
    padding: 15px 0 15px 60px;
    color: #fff;
    // &.active {
    //   background: var(--2, #eabf9f);
    //   color: #fff;
    // }
  }
}
.dropdown {
  width: 100%;
  a {
    &.active {
      background: #ffe4d0;
      color: #333;
    }
  }
}
