.login_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login_wrapper {
  width: 330px;
  height: 332px;
  padding: 30px;
  border: 1px solid rgba($color: #737373, $alpha: 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    position: absolute;
    padding: 15px;
  }

  form {
    height: 198px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .login_input {
    // padding: 15px 20px;
    width: 100%;
    height: 48px;
    padding-left: 50px;
    border: 1px solid rgba($color: #000, $alpha: 0.5);
    color: #000;
    border-radius: 7px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    outline: none;
    &.login_input--danger {
      border: 1px solid red;
    }
    &::placeholder {
      color: rgba($color: #000, $alpha: 0.5);
    }
    &:focus {
      border: 1px solid #000;
    }
    &:nth-child(1) {
      background-position: 20px;
    }
    &:nth-child(2) {
      background-position: 20px;
    }
  }
  button {
    background: #ef8d8d;
    cursor: pointer;
    border: none;
    color: #fff;
    border-radius: 7px;
    padding: 18px 112px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

.login_title {
  color: #000;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 5px;
  margin-bottom: 0;
  text-align: center;
}
