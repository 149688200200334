.language {
  width: 100%;
  height: 48px;
  padding-left: 20px;
  border: 1px solid rgba($color: #df1c1b, $alpha: 0.5);
  color: #01384d;
  border-radius: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  margin-bottom: 30px;
}
.answer {
  width: 162px;
  height: 48px;
  padding-left: 20px;
  border: 1px solid rgba($color: #df1c1b, $alpha: 0.5);
  color: #01384d;
  border-radius: 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  outline: none;
  margin-bottom: 30px;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: #df1c1b;
  width: 25px;
  height: 25px;
  border-radius: 8px;
  border: none;
  i {
    font-size: 18px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
  }
}
